import React, { useState, useEffect, useMemo } from 'react'
import { uniqBy } from 'lodash'
import {
  useCounter,
  parseQuery,
  useApiGet,
  varClass,
  map,
  navigateTo,
  articlePath,
  categoryPath,
  tagColors,
} from '../shared'
import { Header, Loader } from '../layout'

export function ListPage(props) {
  const query = useMemo(() => parseQuery(props.location.search), [])

  const [selectedGroup, setSelectedGroup] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')

  useEffect(() => {
    if (query.grp) {
      setSelectedGroup(query.grp.toLowerCase())
    }
    if (query.cat) {
      navigateTo(categoryPath(query.cat.toLowerCase()))
    }
  }, [query])

  const data = useApiGet('/docs')

  const loaded = data?.articles && Array.isArray(data.articles)

  const categories =
    loaded &&
    uniqBy(
      data.articles
        .filter((article) => {
          if (selectedGroup) {
            if (article.group !== selectedGroup) return false
          }
          return true
        })
        .filter((article) => article.category)
        .map((article) => article.category),
      (category) => category.toLowerCase()
    )
  const topArticles =
    loaded && data.articles.filter((article) => article.isPopular)
  const articles =
    loaded &&
    data.articles.filter((article) => {
      if (selectedGroup) {
        if (article.group !== selectedGroup) return false
      }
      if (selectedCategory) {
        if (article.category.toLowerCase() !== selectedCategory) return false
      }
      return true
    })

  if (!loaded) return <Loader />

  return (
    <main className="list">
      {loaded && (
        <>
          <Header {...props} />
          <div className="page-list">
            <TopArticles {...{ loaded, topArticles }} />
            <CategoriesWithArticles
              {...{
                categories,
                selectedCategory,
                setSelectedCategory,
                articles,
              }}
            />

            <div className="hero2">
              <div className="hero-content">
                <div className="row">
                  <div>
                    <div className="h1">Do you want a helping hand?</div>
                    <div className="h2">
                      Get in touch with our support team and get proper
                      technical guidance
                    </div>
                    <div className="h2 m-t-30">
                      Write us at{' '}
                      <a
                        href="mailto:support@heylink.com"
                        className="email-link"
                      >
                        support@heylink.com
                      </a>
                    </div>
                  </div>

                  <div>
                    {/*
                    <div className="card">
                      <div className="card-avatar" />
                      <div className="card-name">Louise Bønløkke</div>
                      <div className="card-title">Technical Assistance</div>
                      <div className="card-button-container">
                        <button className="card-button">
                          <span>Support</span>
                        </button>
                      </div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </>
      )}
    </main>
  )
}

function TopArticles(props) {
  const { loaded, topArticles } = props

  return (
    <div className="hero">
      <div className="hero-content">
        <div className="h1">Explore our documentations</div>
        <div className="h2">Most Popular Guides</div>
      </div>
      <div className="hero-articles">
        {loaded &&
          map(topArticles, (article) => (
            <TopArticle key={article.uuid} article={article} />
          ))}
      </div>
    </div>
  )
}

function TopArticle(props) {
  const { article } = props
  const { category } = article

  const tagColor = tagColors[category.toLowerCase()]

  return (
    <div className="hero-article">
      <div>
        {category && (
          <div
            className={varClass({
              'tag': true,
              [`text-${tagColor}`]: !!tagColor,
            })}
          >
            {category}
          </div>
        )}
        <div className="article-title">{article.shortTitle}</div>
      </div>
      <a className="article-link" href={articlePath(article)}>
        Read
      </a>
    </div>
  )
}

function CategoriesWithArticles(props) {
  const { categories, selectedCategory, setSelectedCategory, articles } = props
  return (
    <div className="container">
      <Categories {...{ categories, selectedCategory, setSelectedCategory }} />

      <div className="articles">
        {map(articles, (article) => (
          <Article key={article.uuid} article={article} />
        ))}
      </div>
    </div>
  )
}

function Categories(props) {
  const { categories, selectedCategory, setSelectedCategory } = props

  const [render, rerender] = useCounter()
  useEffect(() => {
    rerender()
  }, [selectedCategory])

  return (
    <div key={render} className="articles-categories">
      <button
        className={varClass({
          'articles-category': true,
          'active': !selectedCategory,
        })}
        onClick={() => setSelectedCategory('')}
      >
        <span>All</span>
      </button>
      {map(categories, (key) => (
        <button
          key={key}
          className={varClass({
            'articles-category': true,
            'active': key.toLowerCase() === selectedCategory,
          })}
          onClick={() => setSelectedCategory(key.toLowerCase())}
        >
          <span>{key}</span>
        </button>
      ))}
    </div>
  )
}

function Article(props) {
  const { article } = props
  const { category } = article

  const tagColor = tagColors[category.toLowerCase()]

  return (
    <a className="article" href={articlePath(article)}>
      {category && (
        <div
          className={varClass({
            'tag': true,
            [`text-${tagColor}`]: !!tagColor,
          })}
        >
          {category}
        </div>
      )}
      <div className="article-title">{article.shortTitle}</div>
    </a>
  )
}

// function Footer(props) {
//   return (
//     <footer>
//       <div className="footer-container">
//         <div className="footer-links row row-space-between">
//           <SVG
//             src="/images/logo.svg"
//             width={92}
//             height={23}
//             className="m-t-1"
//           />
//           <div className="footer-linklist">
//             <div>Product</div>
//             <a>Outbound</a>
//             <a>Inbound</a>
//             <a>Pricing</a>
//             <a>Get a demo</a>
//           </div>
//           <div className="footer-linklist">
//             <div>Resources</div>
//             <a>FAQ</a>
//             <a>Guides</a>
//             <a>Documentation</a>
//           </div>
//           <div className="footer-linklist">
//             <div>Legal</div>
//             <a>Terms of service</a>
//             <a>Privacy policy</a>
//             <a>Cookie settings</a>
//           </div>
//           <div className="footer-linklist">
//             <div>Company</div>
//             <a>Inducement</a>
//             <a>Newsroom</a>
//             <a>Career</a>
//           </div>
//           <div className="footer-account">
//             <div>
//               <a className="btn" href="#">
//                 Get Started
//               </a>
//             </div>
//             <div>
//               <a>
//                 <span>Already got an account</span>
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//
//       <div className="footer-copyright">
//         <div className="footer-container row row-center row-space-between h-100">
//           <span className="text-light text-smaller text-bold">
//             2022 © Heylink.com - All right reserved
//           </span>
//           <a href="/sitemap.xml" className="link">
//             Sitemap
//           </a>
//         </div>
//       </div>
//     </footer>
//   )
// }
