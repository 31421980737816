import React from 'react'
import { Layout } from '../components/layout'
import { ListPage } from '../components/list'

const ListPageContainer = (props) => {
  return (
    <Layout>
      <ListPage {...props} />
    </Layout>
  )
}

export default ListPageContainer
